<template>
  <div>
    <!--Breadcrumb-->
    <section>
      <Breadcrumb
        :title="info.searchItem"
        titleType="H1"
        :array="[
          { islink: true, url: '/', text: 'Ana Sayfa' },
          { islink: true, url: '/experiences', text: 'Hizmetler' },
          { isCity: true },
        ]"
        :scity="true"
      />
    </section>
    <!--/Breadcrumb-->

    <!--Add listing-->
    <section class="sptb">
      <div class="container">
        <div class="row">
          <div class="col-xl-9 col-lg-9 col-md-9" id="mobileEx">
            <!--Add lists-->
            <div class="mb-lg-0">
              <template v-if="isExperDetailLoad">
                <ProfileItemSkeleton :list="8" />
              </template>
              <template v-else>
                <ProfileItem :list="list" />
              </template>
              <div class="center-block text-center mt-5">
                <Pagination
                  :currentpage="currentpage"
                  :pagesize="pagesize"
                  :preload="'/experiences/' + $route.params.search + '/page/'"
                />
              </div>
            </div>
            <!--Add lists-->
          </div>
          <div class="col-xl-3 col-lg-4 col-md-12">
            <div class="card" v-for="(item, i) in filter" :key="'cb-' + i">
              <div class="card-header">
                <h3 class="card-title">{{ item.title }}</h3>
              </div>
              <div class="card-body text-primary">
                <div
                  class="row"
                  style="padding-left: 29px"
                >
                  <div
                  :style="item.type == 'radio'?'margin-left:-10px':'margin-left:10px'"
                    :class="
                      'col-12 form-check ' +
                      (item.type == 'check' ? 'form-switch ' : '') +
                      'mb-2'
                    "
                    v-for="(filterObject, a) in item.result"
                    :key="'fo-' + i + '-' + a"
                  >
                    <template v-if="item.type == 'check'">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="checkbox6"
                        :id="'fo-' + i + '-' + a"
                        @click="filterItem(filterObject[1], i, item.type)"
                      />
                      <label class="form-check-label" :for="'fo-' + i + '-' + a"
                        >{{ filterObject[0] }}
                      </label>
                    </template>
                    <template v-if="item.type == 'radio'">
                      <input
                        type="radio"
                        class="form-check-input"
                        name="checkbox6"
                        :id="'fo-' + i + '-' + a"
                        @click="filterItem(filterObject[1], i, item.type)"
                      />
                      <label style="margin-left:10px" class="form-check-label" :for="'fo-' + i + '-' + a"
                        >{{ filterObject[0] }}
                      </label>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <Experience />
          </div>
        </div>
      </div>
    </section>
    <!--Add Listing-->
  </div>
</template>
<style scoped>
@media screen and (max-width: 990px) {
  #mobileEx {
    padding-left: 35px;
    padding-right: 35px;
  }
}
</style>
<script>
import ProfileItem from "./SearchPages/Components/ProfileItem.vue";
import Experience from "./SearchPages/Components/Experience.vue";
import ProfileItemSkeleton from "./SearchPages/Components/ProfileItemSkeleton.vue";
import Breadcrumb from "../components/Breadcrumb.vue";
import Pagination from "../components/Pagination.vue";
export default {
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: this.description,
        },
      ],
    };
  },
  created() {
    this.$store.state.header = true;
    this.$store.state.footer = true;
    var cityInfo = {};
    if (this.$route.params.page) {
      this.currentpage = this.$route.params.page;
    }
    this.$store.dispatch("citysGet", cityInfo).then((value) => {
      this.cityList = value.list;
      if (this.$route.params.location) {
        this.city = this.cityList.filter(
          (country) => country.shortname == this.$route.params.location
        )[0].name;
        this.districtList = this.cityList.filter(
          (country) =>
            country.shortname ==
            (!this.$route.params.location
              ? "adana"
              : this.$route.params.location)
        )[0].districts;
      }
    });
    this.getList();
    this.backToTop();
  },
  data() {
    return {
      cityList: [],
      districtList: [],
      info: [],
      list: [],
      city: "",
      filter: [],
      filtered: "",
      serviceList: [],
      isExperDetailLoad: true,
      currentpage: 1,
      perpage: 9,
      totalsize: 0,
      pagesize: 0,
      title: "",
      description: "",
      filterArray:[],
      loaded:"",
    };
  },
  watch: {
    "$route.params.search": function () {
      (this.isExperDetailLoad = true), this.getList();
    },
    "$route.params.page": function () {
      (this.currentpage = this.$route.params.page), this.getList();
    },
  },
  methods: {
    filterItem(item, i, type) {
      if (this.filtered != "") {
        var a = this.filtered.split("&");
        a.forEach((element) => {
          var b = element.split(".");
          this.filterArray[b[0]] = b[1].split(",");
        });
      }
      if (this.filterArray["_" + i] == undefined) {
        this.filterArray["_" + i] = [];
      }
      if (type == "radio") {
        this.filterArray["_" + i] = [];
      }
      //Listede var mı?
      var found = this.filterArray["_" + i].indexOf(item);
      if (found < 0) {
        this.filterArray["_" + i].push(item);
      } else {
        this.filterArray["_" + i].splice(found, 1);
      }
      var string = [];
      for (var key in this.filterArray) {
        string.push(key + "." + this.filterArray[key]);
      }
      this.filtered = string.join("&").toString();
      this.getList();
    },
    backToTop() {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        0
      );
      return false;
    },
    locationChange() {
      let city = document.querySelector("select[name=city]").value;
      this.districtList = this.cityList.filter(
        (country) => country.shortname == city
      )[0].districts;
      this.$router.push("/search/" + city + "/" + this.$route.params.search);
      this.getList();
    },
    disctrictChange() {
      let district = document.querySelector("select[name=district]").value;
      this.$router.push(
        "/city/" +
          this.$route.params.location +
          "/" +
          district +
          "/" +
          this.$route.params.search
      );
    },
    getList() {
      this.backToTop();
      this.list = [];
      let experienceInfo = {};
      if (this.$route.params.search != null) {
        experienceInfo = {
          search: this.$route.params.search,
          begin: (this.currentpage - 1) * this.perpage,
          perpage: this.perpage,
        };
      }
    
      var array=[]
      if (this.filtered != "") {
        var a = this.filtered.split("&")
        
        a.forEach(element => {
          var b = element.split('.')
          array.push(b[1])
        });
      }
      this.loaded = array.toString();
      experienceInfo.filtered = this.loaded
      this.$store
        .dispatch("serviceUserFilter", experienceInfo)
        .then((value) => {
          this.info = value.info;
          this.title =
            value.info == null
              ? "Sonuç Bulunamadı"
              : value.info.searchItem +
                " - Bilgi, doktorlara, uzmanlara, terapistlere sıkça sorulan sorular";
          this.description =
            value.info.searchItem +
            " - Türkiye’deki en iyi doktorları, uzmanları, terapistleri bulun ve onlarla online, yüz yüze, evde terapi randevusu alın.";
          let _this = this;
          $(document).ready(function () {
            let content = document.getElementById("appcontent").innerHTML;
            _this.$store.dispatch("setMetaContent", [
              _this.title,
              _this.description,
              "",
              "",
              "",
              content,
            ]);
          });
          /*meta.description.content =
            (value.info.experience_description!=null?value.info.experience_description:"") +
            " ihtiyacınız olan terapiyi ve terapisti bulun";*/
          this.filter = [];
          this.list = value.users;
          /*if (this.list.length == 0) {
            setTimeout(() => {
              this.$router.push(this.$route.params.search + "/detail");
            }, 1500);
          }*/
          this.filter = value.filter;
          this.isExperDetailLoad = false;
          this.totalsize = value.size;
          this.pagesize = value.pages;
        });
    },
  },
  components: {
    ProfileItem,
    Experience,
    ProfileItemSkeleton,
    Breadcrumb,
    Pagination,
  },
  mounted() {
    this.backToTop();
    let serviceInfo = {};
    this.$store.dispatch("serviceList", serviceInfo).then((value) => {
      this.serviceList = value;
    });
  },
};
</script>